import Image from "next/image";

const Location = () => {
  return (
    <section className='flex flex-col py-24 relative'>
      <div className='block absolute inset-0 opacity-25'>
        <Image
          alt='Latest information and technology at Charmelle London'
          src='/new-room.png'
          className='object-cover'
          quality={50}
          fill
          sizes="100vw" />
      </div>
      <div className='relative z-10 w-full'>
        <div className='flex justify-center px-4'>
          <iframe
            data-hj-allow-iframe=""
            title="Charmelle London"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9954.420037526428!2d0.0098833!3d51.410312!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7648309f18ad8e42!2sCharmelle+London!5e0!3m2!1sen!2suk!4v1552931761803"
            width="600"
            height="450"
            frameBorder="0"
            allowFullScreen
            className='w-full max-w-screen-lg shadow-md rounded-md'
          />
        </div>
      </div>
    </section>
  );
}

export default Location
